import React, { useRef, useEffect, useState, useCallback } from "react"
import { motion, useScroll, useTransform } from "framer-motion"
import Typography from "aldoo-ra/Typography"

const TEXT_WIDTH_RATIO = 0.9
const BASE_FONT_SIZE = 64

const AnimatedWord = React.forwardRef(
  (
    {
      word,
      index,
      totalWords,
      adjustedScrollProgress,
      sequence,
      isLastWordInLine,
    },
    ref
  ) => {
    const revealDuration = sequence.REVEAL_END - sequence.REVEAL_START
    const wordStart =
      sequence.REVEAL_START + (index * revealDuration) / totalWords

    const opacity = useTransform(
      adjustedScrollProgress,
      [0, wordStart, wordStart + 0.01, 1],
      [0.5, 0.5, 1, 1]
    )

    return (
      <motion.span
        ref={ref}
        style={{ opacity }}
        className={`inline-block ${index > 0 ? "ml-2 md:ml-4 lg:ml-6" : ""}`}
      >
        {word}
        {isLastWordInLine && <br />}
      </motion.span>
    )
  }
)

const getTextWidth = (text, font) => {
  const canvas = document.createElement("canvas")
  const context = canvas.getContext("2d")
  context.font = font
  return context.measureText(text).width
}

export default function TypewriterScrollText({ text, scrollMultiplier = 1 }) {
  const containerRef = useRef(null)
  const typographyWrapperRef = useRef(null)
  const firstWordRef = useRef(null)
  const fontSizeRef = useRef(BASE_FONT_SIZE)
  const [fontSize, setFontSize] = useState(BASE_FONT_SIZE)
  const [dimensions, setDimensions] = useState({ height: 0 })
  const [initialLayoutDone, setInitialLayoutDone] = useState(false)
  const [reveal, setReveal] = useState(0)
  const [isSmallScreen, setIsSmallScreen] = useState(false)
  const [processedText, setProcessedText] = useState(text)
  const [measuredFontFamily, setMeasuredFontFamily] = useState(null)

  const processText = useCallback((inputText, forSmallScreen) => {
    if (forSmallScreen) {
      if (inputText.includes("<br>")) {
        return inputText
      } else {
        const words = inputText.split(" ")
        const halfwayPoint = Math.ceil(words.length / 2)
        const firstLine = words.slice(0, halfwayPoint).join(" ")
        const secondLine = words.slice(halfwayPoint).join(" ")
        return `${firstLine}<br>${secondLine}`
      }
    } else {
      return inputText.replace(/<br>/g, " ")
    }
  }, [])

  useEffect(() => {
    const newProcessedText = processText(text, isSmallScreen)
    setProcessedText(newProcessedText)
  }, [text, isSmallScreen, processText])

  const lines = processedText.split("<br>")
  const lineWords = lines.map((line) => line.split(" "))
  const wordsWithLineInfo = []
  let totalWordCount = 0

  lineWords.forEach((line, lineIndex) => {
    line.forEach((word, wordIndex) => {
      const isLastWordInLine =
        wordIndex === line.length - 1 && lineIndex < lines.length - 1
      wordsWithLineInfo.push({
        word,
        isLastWordInLine,
        wordIndex: totalWordCount,
      })
      totalWordCount++
    })
  })

  const longestLine = lines.reduce(
    (longest, current) => (current.length > longest.length ? current : longest),
    ""
  )

  const calculateOptimalFontSize = useCallback(() => {
    if (!typographyWrapperRef.current) return fontSizeRef.current

    const containerWidth = window.innerWidth * TEXT_WIDTH_RATIO
    let fontFamily = measuredFontFamily || "'Arial'"
    if (!measuredFontFamily) {
      const computedStyle = window.getComputedStyle(
        typographyWrapperRef.current
      )
      fontFamily = computedStyle.fontFamily || fontFamily
    }

    let minSize = 10
    let maxSize = 200
    let currentSize = fontSizeRef.current
    let bestSize = fontSizeRef.current
    let attempts = 0
    const maxAttempts = 20

    while (minSize <= maxSize && attempts < maxAttempts) {
      attempts++
      currentSize = Math.floor((minSize + maxSize) / 2)
      const fontString = `${currentSize}px ${fontFamily}`
      const textWidth = getTextWidth(longestLine, fontString)

      if (Math.abs(textWidth - containerWidth) < 5) {
        bestSize = currentSize
        break
      }

      if (textWidth > containerWidth) {
        maxSize = currentSize - 1
      } else {
        bestSize = currentSize
        minSize = currentSize + 1
      }
    }

    return bestSize
  }, [longestLine, measuredFontFamily])

  useEffect(() => {
    if (firstWordRef.current && initialLayoutDone && reveal === 1) {
      const computedStyle = window.getComputedStyle(firstWordRef.current)
      setMeasuredFontFamily(computedStyle.fontFamily)
    }
  }, [initialLayoutDone, reveal])

  useEffect(() => {
    const clear = setTimeout(() => {
      setReveal(1)
    }, 1000)
    return () => clearTimeout(clear)
  }, [])

  useEffect(() => {
    const checkScreenSize = () => {
      const smallScreen = window.innerWidth < 1024
      setIsSmallScreen(smallScreen)
    }

    const calculateDimensions = () => {
      if (containerRef.current) {
        setDimensions({
          height: window.innerHeight * 1.2,
        })
      }
    }

    checkScreenSize()
    calculateDimensions()

    const layoutTimer = setTimeout(() => {
      setInitialLayoutDone(true)
    }, 100)

    const handleResize = () => {
      checkScreenSize()
      calculateDimensions()
      if (initialLayoutDone && reveal === 1) {
        const newSize = calculateOptimalFontSize()
        if (Math.abs(newSize - fontSizeRef.current) > 2) {
          fontSizeRef.current = newSize
          setFontSize(newSize)
        }
      }
    }

    let resizeTimer
    const throttledResize = () => {
      if (!resizeTimer) {
        resizeTimer = setTimeout(() => {
          handleResize()
          resizeTimer = null
        }, 100)
      }
    }

    window.addEventListener("resize", throttledResize)

    return () => {
      window.removeEventListener("resize", throttledResize)
      clearTimeout(layoutTimer)
    }
  }, [initialLayoutDone, reveal, calculateOptimalFontSize])

  useEffect(() => {
    if (initialLayoutDone && reveal === 1) {
      const timer = setTimeout(() => {
        const newSize = calculateOptimalFontSize()
        fontSizeRef.current = newSize
        setFontSize(newSize)
      }, 500)

      return () => clearTimeout(timer)
    }
  }, [initialLayoutDone, reveal, calculateOptimalFontSize, measuredFontFamily])

  useEffect(() => {
    if (initialLayoutDone) {
      const simulateScroll = () => {
        window.dispatchEvent(new Event("scroll"))
      }

      simulateScroll()
      const updateInterval = setInterval(simulateScroll, 100)
      const cleanupTimer = setTimeout(() => {
        clearInterval(updateInterval)
        simulateScroll()
      }, 3000)

      return () => {
        clearInterval(updateInterval)
        clearTimeout(cleanupTimer)
      }
    }
  }, [initialLayoutDone])

  const { scrollYProgress } = useScroll({
    target: containerRef,
    offset: ["start end", "end start"],
  })

  const adjustedScrollProgress = useTransform(scrollYProgress, (value) => {
    const centered = value - 0.5
    const adjusted = centered * scrollMultiplier
    return Math.max(0, Math.min(1, adjusted + 0.5))
  })

  const SEQUENCE = {
    REVEAL_START: 0.2,
    REVEAL_END: 0.8,
  }

  const top = useTransform(
    adjustedScrollProgress,
    [0, 0.3, 0.7, 1],
    ["100vh", "50%", "50%", "-50vh"]
  )

  const y = useTransform(
    adjustedScrollProgress,
    [0, 0.3, 0.7, 1],
    ["0%", "-50%", "-50%", "-50%"]
  )

  const containerOpacity = useTransform(
    adjustedScrollProgress,
    [0, 0.1, 0.3, 0.7, 0.9, 1],
    [0, 0.5, 1, 1, 0.5, 0]
  )

  return (
    <section
      ref={containerRef}
      className="relative"
      style={{
        height: `${dimensions.height}px`,
        opacity: reveal,
      }}
    >
      <div className="flex items-center justify-center h-screen sticky top-0">
        <motion.div
          style={{
            position: "fixed",
            top,
            y,
            opacity: containerOpacity,
            zIndex: 10,
          }}
          className="w-full max-w-8xl px-4"
        >
          <div
            ref={typographyWrapperRef}
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Typography
              variant="title1"
              style={{
                fontSize: `${fontSize}px`,
                maxWidth: `${TEXT_WIDTH_RATIO * 100}%`,
                whiteSpace: isSmallScreen ? "normal" : "nowrap",
                textAlign: "center",
                lineHeight: isSmallScreen ? 1.2 : 1,
              }}
            >
              {wordsWithLineInfo.map((wordInfo, index) => (
                <AnimatedWord
                  ref={index === 0 ? firstWordRef : null}
                  key={index}
                  word={wordInfo.word}
                  index={wordInfo.wordIndex}
                  totalWords={totalWordCount}
                  adjustedScrollProgress={adjustedScrollProgress}
                  sequence={SEQUENCE}
                  isLastWordInLine={wordInfo.isLastWordInLine}
                />
              ))}
            </Typography>
          </div>
        </motion.div>
      </div>
    </section>
  )
}
