import React, { useMemo, useEffect, useState } from "react"
import FeaturesScroll from "./FeaturesScroll"
import Button from "aldoo-ra/Button"
import Text from "aldoo-ra/Text"
import MenuGroup from "aldoo-ra/MenuGroup"
import Value from "aldoo-ra/Value"
import { useFeatureController } from "./FeatureController"
import CMSData from "aldoo-ra/CMS/cms-data"

export default function FeaturesAndBenefits() {
  const [platform] = Value("site_platform")

  // Initialize the controller once at the parent level
  const controller = useFeatureController({
    //just change the features, don't change the URL
    skipURLSync: true,
  })

  const platformsQuery = CMSData({
    contentType: "AquaMailFeaturePlatform",
  })

  // Current platform ID
  const currentPlatformId = useMemo(() => {
    return platformsQuery.data?.find((p) => p.id === platform)?._id
  }, [platformsQuery.data, platform])

  const {
    data: highlights,
    refresh: loadFeatures,
    loading,
  } = CMSData({
    contentType: "HighlightFeatures",
    match: {
      "content.platform._id": currentPlatformId,
    },
    manual: true,
  })

  useEffect(() => {
    if (!currentPlatformId || loading) return
    loadFeatures()
  }, [currentPlatformId])

  if (!highlights.length) return null
  const features = highlights[0].features

  return (
    <div>
      <Text
        id="features_benefits_title"
        component="h2"
        variant="h1"
        className="text-center mb-2"
      />
      <div>
        <FeaturesScroll
          overwriteFeatures={features}
          controller={controller}
          cardDistance={220}
          scrollHeight={{
            default: 520,
            xs: 520,
            sm: 540,
            md: 480,
            lg: 500,
          }}
        />
      </div>

      {/* try free button */}
      <div className="w-full flex justify-center">
        <MenuGroup
          name="TryAppFree"
          renderAs="hlist"
          itemsAsLinks={false}
          itemRender={(item) => {
            const url =
              (item?.link[0]?.parameters &&
                item?.link[0]?.parameters[platform]) ||
              item?.link[0]?.url

            return (
              <Button variant="primary_l">
                <a href={url} target="_blank">
                  {item?.label}
                </a>
              </Button>
            )
          }}
        />
      </div>
    </div>
  )
}
