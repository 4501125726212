import React from "react"
import CMSRender from "aldoo-ra/CMS/cms-render"
import Text from "aldoo-ra/Text"
import PostCard from "../../components/Blog/post-card"

const LatestArticles = () => {
  return (
    <CMSRender
      contentType="BlogPost"
      sort={{ "content.publish_date": -1 }}
      page={1}
      itemsPerPage={3}
      match={{
        "content.status": "Published",
      }}
      renderItem={(post) => <PostCard post={post} />}
    />
  )
}

const LatestBlogArticlesSection = () => {
  return (
    <div className="w-full flex flex-col items-center justify-center pb-[90px] md:pb-14 md:pt-[90px] lg:pt-[104px] px-4">
      <Text
        id="latest_articles_title"
        variant="h1"
        className="text-center mb-[40px] md:mb-[88px]"
      />
      <div className="flex flex-col md:flex-row w-full max-w-[465px] md:max-w-w1_xl flex-nowrap justify-between gap-y-10 md:gap-y-0">
        <LatestArticles />
      </div>
    </div>
  )
}

export default LatestBlogArticlesSection
