import React, { useRef, useEffect } from "react"
import CMSData from "aldoo-ra/CMS/cms-data"
import TypewriterScrollText from "./TypewriterScrollText"

export default function TypewriterSection() {
  const container = useRef(null)

  const { data } = CMSData({
    contentType: "Text",
    match: { "content.id": "typewriter_text" },
  })
  const content = data[0]

  return (
    <div ref={container} className="min-h-[100vh]">
      {content && (
        <TypewriterScrollText scrollMultiplier={2} text={content?.text} />
      )}
    </div>
  )
}
