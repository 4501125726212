import React, { useState, useEffect } from "react"
import CMSRender from "aldoo-ra/CMS/cms-render"
import Typography from "aldoo-ra/Typography"
import WaveBG from "./WaveBG"
import parse from "html-react-parser"
import { api } from "aldoo-ra/API"
import { store } from "aldoo-ra/Store"
import ExpressionParser from "aldoo-ra/ExpressionParser"
import Localization from "aldoo-ra/Localization"
import Value from "aldoo-ra/Value"
import MenuGroup from "aldoo-ra/MenuGroup"



// Import the store API
const StoreAPI = api("store")

const Card = ({ data }) => {
  const [platform, setPlatform] = Value("site_platform")
  const [isHovered, setIsHovered] = useState(false)
  const [screenSize, setScreenSize] = useState("2xl")
  const [product, setProduct] = useState(null)
  const {
    icon,
    title,
    title_mobile,
    yes,
    no,
    description,
    icons,
    store_product,
  } = data
  const { lt } = Localization()

  // Fetch product information
  useEffect(() => {
    const fetchProduct = async () => {
      if (!store_product?.item) return

      try {
        const result = await StoreAPI({
          resolve_price: {
            products: [store_product.item],
            platform: "Web",
          },
        })

        if (result && result[0]) {
          const productData = result[0]

          // Localize product name and description if available
          if (productData.name) {
            productData.name = await lt(productData.name)
          }
          if (productData.description) {
            productData.description = await lt(productData.description)
          }

          setProduct(productData)
        }
      } catch (error) {
        console.error("Error fetching product:", error)
      }
    }

    fetchProduct()
  }, [store_product])

  useEffect(() => {
    const checkScreenSize = () => {
      const width = window.innerWidth
      if (width < 768) {
        setScreenSize("mobile")
      } else if (width < 1024) {
        setScreenSize("md")
      } else if (width < 1280) {
        setScreenSize("lg")
      } else if (width < 1536) {
        setScreenSize("xl")
      } else {
        setScreenSize("2xl")
      }
    }

    checkScreenSize()
    window.addEventListener("resize", checkScreenSize)
    return () => window.removeEventListener("resize", checkScreenSize)
  }, [])

  const getResponsivePosition = (positions) => {
    return positions[screenSize] || positions["2xl"]
  }

  const scatteredIcons = [
    {
      src: icons[0],
      positions: {
        mobile: { x: "5%", y: "-5px" },
        md: { x: "20%", y: "-40px" },
        lg: { x: "9%", y: "-45px" },
        xl: { x: "10%", y: "-48px" },
        "2xl": { x: "10%", y: "-50px" },
      },
      rotate: "0deg",
      delay: "0s",
    },
    {
      src: icons[1],
      positions: {
        mobile: { x: "50%", y: "-50px" },
        md: { x: "75%", y: "-40px" },
        lg: { x: "80%", y: "-45px" },
        xl: { x: "82%", y: "-48px" },
        "2xl": { x: "85%", y: "-50px" },
      },
      rotate: "0deg",
      delay: "0.1s",
    },
    {
      src: icons[2],
      positions: {
        mobile: { x: "95%", y: "-15px" },
        md: { x: "97%", y: "140px" },
        lg: { x: "105%", y: "150px" },
        xl: { x: "108%", y: "155px" },
        "2xl": { x: "110%", y: "160px" },
      },
      rotate: "0deg",
      delay: "0.15s",
    },
    {
      src: icons[3],
      positions: {
        mobile: { x: "5%", y: "calc(100% + 45px)" },
        md: { x: "0px", y: "40%" },
        lg: { x: "-75px", y: "42%" },
        xl: { x: "-90px", y: "44%" },
        "2xl": { x: "-100px", y: "45%" },
      },
      rotate: "0deg",
      delay: "0.05s",
    },
    {
      src: icons[4],
      positions: {
        mobile: { x: "50%", y: "calc(100% + 90px)" },
        md: { x: "95%", y: "calc(100% + 40px)" },
        lg: { x: "103%", y: "calc(100% + 45px)" },
        xl: { x: "104%", y: "calc(100% + 50px)" },
        "2xl": { x: "105%", y: "calc(100% + 60px)" },
      },
      rotate: "0deg",
      delay: "0.2s",
    },
    {
      src: icons[5],
      positions: {
        mobile: { x: "95%", y: "calc(100% + 50px)" },
        md: { x: "20px", y: "calc(100% + 25px)" },
        lg: { x: "-45px", y: "calc(100% + 30px)" },
        xl: { x: "-50px", y: "calc(100% + 35px)" },
        "2xl": { x: "-60px", y: "calc(100% + 40px)" },
      },
      rotate: "0deg",
      delay: "0.12s",
    },
  ]

  const handleYesClick = () => {
    if (!product) return
    store.addToCart({
      product: product,
      proceedToCart: true,
    })
  }

  return (
    <div className="flex items-center justify-center min-h-screen">
      <div className="relative w-full px-4 md:px-0 mx-auto">
        {/* Background Icons Container */}
        <div
          className="absolute inset-0 mx-auto"
          style={{
            width: screenSize === "mobile" ? "70%" : "700px",
            maxWidth: screenSize === "mobile" ? "400px" : "700px",
          }}
        >
          {scatteredIcons.map(({ src, positions, rotate, delay }, index) => {
            const currentPositions = getResponsivePosition(positions)
            const startLeft = currentPositions.x.startsWith("calc")
              ? currentPositions.x
              : `calc(${currentPositions.x})`
            const startTop = currentPositions.y.startsWith("calc")
              ? currentPositions.y
              : `calc(${currentPositions.y})`

            return (
              <div
                key={index}
                className="absolute"
                style={{
                  left: startLeft,
                  top: startTop,
                  transform: `translate(-50%, -50%)`,
                  transition: `all 1.2s cubic-bezier(0.22, 1, 0.36, 1) ${delay}`,
                  ...(isHovered && {
                    left: "50%",
                    top: "50%",
                    transform: `translate(-50%, -50%) scale(0.9) rotate(0deg)`,
                  }),
                  ...(!isHovered && {
                    transform: `translate(-50%, -50%) rotate(${rotate})`,
                  }),
                }}
              >
                <div
                  className={`${
                    screenSize === "mobile"
                      ? "w-[120px] h-[120px]"
                      : "w-[150px] h-[150px]"
                  }`}
                >
                  <img src={src} alt="feature icon" className="w-full h-full" />
                </div>
              </div>
            )
          })}
        </div>

        {/* Card */}
        <div className="w-full max-w-[390px] md:max-w-full md:w-[600px] lg:w-[650px] xl:w-[690px] 2xl:w-[740px] h-[568px] md:h-auto md:aspect-[886/568] bg-theme_white rounded-[24px] shadow-custom_card py-[52px] px-10 md:p-8 lg:p-10 relative overflow-hidden z-10 mx-auto">
          {/* Email Icon - temporary mt until CTA texts are hidden */}
          <div className="flex justify-center mt-10 mb-6">
            <div className="w-[126px] md:w-[90px] lg:w-[100px] 2xl:w-[118px] h-auto">
              <img
                src={icon}
                alt="email icon"
                className="w-full h-full object-contain"
              />
            </div>
          </div>

          {/* Wave Background */}
          <WaveBG
            waveProps={{
              frequency: 0.95,
              amplitude: 80,
              phase: -1000,
            }}
            className="text-wavy/30"
            waveStyle={{
              width: "1200px",
              height: "250px",
              transition: "transform 1.2s cubic-bezier(0.22, 1, 0.36, 1)",
              transform: isHovered ? "translateX(-25%)" : "translateX(0)",
            }}
          />

          {/* Content */}
          <div className="relative flex flex-col items-center z-10">
            <Typography className="block md:hidden w-full text-center text-[40px] xl:text-[48px] 2xl:text-[52px] leading-normal tracking-custom_1 font-bold text-text mt-0 mb-6 mx-auto">
              {parse(title_mobile)}
            </Typography>

            <Typography className="hidden md:block w-full text-center text-[40px] xl:text-[48px] 2xl:text-[52px] leading-normal tracking-custom_1 font-bold text-text mt-0 mb-6 mx-auto">
              {title}
            </Typography>

            <div className="flex justify-center gap-4 mb-8 md:mb-4">
              {/* Temporary hide the button leading to shopping cart */}
              {/* <button
                className="w-auto min-w-[105px] h-[66px] md:h-[52px] lg:h-[58px] flex items-center justify-center gap-[14px] bg-primary hover:bg-primary_hov text-white text-[24px] font-bold rounded-full px-[16px] md:px-[18px] lg:px-[20px] xl:px-[20px] transition-all duration-300"
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
                onClick={handleYesClick}
              >
                {yes}
              </button> */}

              <MenuGroup
                name="OrganizedBannerYes"
                renderAs="hlist"
                itemsAsLinks={false}
                itemRender={(item) => {
                  const url =
                    (item?.link[0]?.parameters &&
                      item?.link[0]?.parameters[platform]) ||
                    item?.link[0]?.url
                  return (
                    <button 
                      className="w-auto min-w-[105px] h-[66px] md:h-[52px] lg:h-[58px] flex items-center justify-center gap-[14px] bg-primary hover:bg-primary_hov text-white text-[24px] font-bold rounded-full px-[16px] md:px-[18px] lg:px-[20px] xl:px-[20px] transition-all duration-300"
                      onMouseEnter={() => setIsHovered(true)}
                      onMouseLeave={() => setIsHovered(false)}
                    >
                      <a href={url} target="_blank">
                        {yes}
                      </a>
                    </button>
                  )
                }}
              />

              <button className="px-8 py-2 text-primary text-[24px]">
                {no}
              </button>
            </div>

            {/* CTA description texts temporarily hidden */}
            {/* <div className="text-center text-gray_2">
              {description.map((desc, index) => (
                <Typography
                  key={index}
                  variant="body_22"
                  className="text-gray_2 mb-0 mt-2 xl:mt-3 2xl:mt-4"
                  dangerouslySetInnerHTML={{
                    __html: desc.replace(
                      /<b>(.*?)<\/b>/g,
                      '<span class="text-text font-bold">$1</span>'
                    ),
                  }}
                />
              ))}
            </div> */}
          </div>
        </div>
      </div>
    </div>
  )
}

const OrganizedBanner = () => {
  return (
    <CMSRender
      contentType="OrganizedBanner"
      renderItem={(data) => <Card data={data} />}
    />
  )
}

export default OrganizedBanner
